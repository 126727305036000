// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const ServiceType = {
  "GENERAL": "GENERAL",
  "DATA_ANALYTICS": "DATA_ANALYTICS",
  "CREDIT_RISK_MANAGEMENT": "CREDIT_RISK_MANAGEMENT",
  "PROJECT_MANAGEMENT": "PROJECT_MANAGEMENT"
};

const { Subscribers, Contacts } = initSchema(schema);

export {
  Subscribers,
  Contacts,
  ServiceType
};