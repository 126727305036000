function Inprint() {
    return (
        <>
            <div className="Inprint min-h-screen">
                <div className="text-center p-10">
                    <h1 className="font-bold text-2xl p-10">Inprint</h1>
                    <p>according to §5 TMG (German Tele Media Act)</p>
                </div>
                <div className="container mx-auto max-w-4xl">
                    <div className="flex flex-col gap-3">
                        <div>
                            <h1 className="font-bold">Owner</h1>
                            <p>Isaac Hemans</p>
                        </div>
                        <div>
                            <h1 className="font-bold">General</h1>
                            <p>Hemans Consult</p>
                            <address>
                                <p>Niederbeckstrasse 20</p>
                                <p>40472, Düsseldorf</p>
                                <p>Germany</p>
                            </address>
                        </div>
                        <div>
                            <h1 className="font-bold">Contact</h1>
                            <a href="mailto:info@hemansconsult.com">info@hemansconsult.com</a>
                            <p>+4915678865073</p>
                        </div>
                        <div>
                            <h1 className="font-bold">VAT ID</h1>
                            <p>DE361611295</p>
                        </div>
                        <div>
                            <h1 className="font-bold">Credit Identification Number</h1>
                            <p>DE95ZZZ00002617162</p>
                        </div>
                    </div>
                </div>
                <br />
                <div className="container mx-auto max-w-4xl">
                    <div className="flex flex-col justify-center">
                        <h1 className="font-bold">Data Provided</h1>
                        <p className="text-justify">
                            Hemans Consult (owner - Isaac Hemans) is obligated to publish contact information in the Imprint/Impressum.
                            The use of such contact information by third parties for the purpose of distributing unsolicited advertisements or other commercial information is prohibited.
                            Hemans Consult reserves the right to take legal measures in case of being sent unsolicited commercial information, e.g. spam mails etc.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Inprint;