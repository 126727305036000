import { NavLink } from "react-router-dom";

function Footer() {
    const year = new Date().getFullYear()

    return (
        <nav className="bg-cyan-900 text-white w-full p-3 pb-20">
            <div className="container mx-auto max-w-6xl">
                <div className="flex justify-between flex-wrap">
                    <div className="flex flex-col pb-5">
                        <p>&copy; {year} Hemans Consult</p>
                        <p></p>
                    </div>
                    <div className="flex flex-col">
                        <NavLink to="/inprint">Inprint</NavLink>
                        <NavLink to="/privacy">Privacy</NavLink>
                    </div>
                    <div>
                        <p>&#x40;:&nbsp;
                            <span>
                                <a href="mailto:info@hemansconsult.com">info@hemansconsult.com</a>
                            </span>
                        </p>
                        <p>+49 211 16346710</p>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Footer;