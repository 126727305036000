import MainNavBar from "../../components/MainNavBar";
import Footer from "../../components/Footer";
import {Outlet} from "react-router-dom";

import {Authenticator} from '@aws-amplify/ui-react';

const Dashboard = ({ signOut, user }) =>  {

    return (
        <Authenticator variation="modal" hideSignUp={true}>
            <div className="Dashboard relative ">
                <MainNavBar/>
                <div className="min-h-screen">
                    <Outlet user={user}/>
                </div>
                <Footer/>
            </div>
        </Authenticator>
    );

}

export default Dashboard;