import { NavLink } from "react-router-dom";
import { useCookies } from "react-cookie";

function Consent() {
    const [cookies, setCookie] = useCookies(['x_consent']);

    return (
        <div className="p-5 bg-white z-[10] fixed w-full bottom-0 shadow">
            <div className="flex justify-between flex-wrap">
                <p className="text-justify md:w-[80%] p-2">
                    This website uses cookies to ensure you have the best browsing experience on the website.
                    Please read our <span className="underline font-bold"><NavLink to="/privacy">privacy policy</NavLink></span> for more information about how we use cookies.
                </p>
                <div className="p-2">
                    <button
                        onClick={
                            () => {
                                setCookie('x_consent', true, { path: '/', sameSite: 'Lax' })
                            }
                        }
                        className="bg-cyan-700 py-2 px-5 text-white">
                        Ok
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Consent;