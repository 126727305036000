import { NavLink } from "react-router-dom"

function NavLinkButton({ to, text, inverse }) {
    return (
        <NavLink to={to}
            className={`p-3 px-4 rounded hover:scale-110 transition-all ease-linear 
            ${inverse ? "border-white border-2 text-white" : "text-white font-bold bg-cyan-700"}`}>
            {text}
        </NavLink>
    )
}

export default NavLinkButton;

// text-cyan-700 font-bold bg-white