import WelcomeBar from "../components/WelcomeBar";

function Home() {
    return (
        <div className="Home">
            <WelcomeBar />
        </div>
    );
}

export default Home;