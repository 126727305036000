import RiskImage from "../images/risk.png";
import OptimizeImage from "../images/optimize.png";
import Analytics from "../images/ana.jpg";
import {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import SubscriberForm from "./SubscriberForm";

function WelcomeBar() {

    const ref1 = useRef();
    const isVisible1 = useIsVisible(ref1);

    const refHomeWhyUs = useRef();
    const refHomeWhyUsIsVisible = useIsVisible(refHomeWhyUs)

    return (
        <>
            <div className="flex flex-col justify-center items-center py-20 sm:py-24 
            lg:py-32 bg-gradient-to-tr from-cyan-100 to-cyan-400 min-h-[80vh] shadow">
                <div className="text-center p-2 max-w-5xl">
                    <h1 className="animate-fade-down animate-once font-extrabold text-5xl sm:text-6xl md:text-7xl tracking-wider">
                        Develop data solutions that <span className="text-cyan-700">improve</span> your business <span
                        className="text-cyan-700">performance</span> and <span
                        className="text-cyan-700">efficiency</span>.
                    </h1>
                    <p className="font-bold text-xl p-3 text-center my-3">
                        At <span className="text-cyan-700">Hemans Consult,</span> we provide a wide range of services to
                        help you <span className="text-cyan-700">optimize</span> your business using <span
                        className="text-cyan-700">Data</span> .
                    </p>
                </div>
            </div>
            <div className="container mx-auto p-2 pt-10 md:max-w-6xl lg:max-w-7xl">
                <div className="max-w-5xl mx-auto rounded-2xl pt-8 pb-10
                    animate-fade-down animate-once animate-ease-in">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-3 bg-cyan-300 shadow">
                        <img className="w-full" src={Analytics} alt="analytics"/>
                        <div className="flex flex-col justify-between m-2">
                            <h2 className="text-3xl p-2 font-extrabold">Data drives decisions</h2>
                            <p className="p-2">
                                Build insights from your business data and make <span
                                className="font-bold">informed</span> business decisions that impact profitability.
                                Our experts in analytics will help you focus on your core business offering by
                                providing you with timely insights from your data.
                                We provide processes that automate reporting and monitoring.
                                <br/>
                                We are ready to support you.
                            </p>
                            <br/>
                            <Link className="text-cyan-700 font-bold p-2 underline" to="/contact" reloadDocument>
                                Talk with us
                            </Link>
                        </div>
                    </div>
                </div>
                <div ref={ref1}
                     className={`transition-opacity ease-in duration-700 ${isVisible1 ? "opacity-100" : "opacity-0"}`}>
                    <div className="max-w-5xl mx-auto md:max-w-6xl">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 pt-10">
                            <div className="pb-3 flex flex-col items-center">
                                <img className="h-20" src={RiskImage} alt=""/>
                                <h5 className="text-center border-b-4 border-cyan-700 py-3 rounded-md
                        hover:cursor-pointer font-bold text-lg hover:text-xl transition-all ease-linear">Credit Risk
                                    Management</h5>
                                <p className="p-2 pt-5 text-justify">
                                    Whether B2C or B2B, our experts in credit risk management can help you develop a
                                    credit
                                    risk
                                    framework that minimizes risk and credit losses.
                                    Optimizing on-boarding process, underwriting, risk strategies and scoring.
                                    Monitor customer payment behaviour and integrate loss mitigation actions into credit
                                    management workflow.
                                </p>
                            </div>
                            <div className="pb-3 flex flex-col items-center">
                                <img className="" src={OptimizeImage} alt=""/>
                                <h5 className="text-center border-b-4 border-cyan-700 py-3 rounded-md hover:cursor-pointer font-bold text-lg hover:text-xl transition-all ease-linear">
                                    Project Management
                                </h5>
                                <p className="p-2 pt-5 text-justify">
                                    Finding the right solution to optimize your internal processes and automate
                                    necessary
                                    tasks
                                    should not be too costly.
                                    Hemans helps you find tailored solutions with focus on <span
                                    className="font-bold">lean</span> and <span className="font-bold">efficiency</span>.
                                    Whether
                                    in-house
                                    hosted or cloud-based, we will help you find a solution that provides best value to
                                    your
                                    business.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    id="home-why-us"
                    ref={refHomeWhyUs}
                    className={`transition-opacity ease-in duration-700 ${refHomeWhyUsIsVisible ? "opacity-100" : "opacity-0"}`}>
                    <div className="flex justify-evenly items-center py-20 flex-wrap gap-10">
                        <div className="text-center">
                            <hr/>
                            <p className="font-extrabold text-4xl sm:text-5xl md:text-6xl py-5">
                                Empathy based support that creates solutions
                            </p>
                            <hr/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-cyan-700 p-5">
                <div className="max-w-6xl mx-auto">
                    <SubscriberForm/>
                </div>
            </div>
        </>
    )
}

export default WelcomeBar

export function useIsVisible(ref) {
    const [isIntersecting, setIntersecting] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIntersecting(entry.isIntersecting)
            }
        );
        observer.observe(ref.current);
        return () => {
            observer.disconnect();
        };
    }, [ref]);

    return isIntersecting;
}