import {useState} from "react";
import {DataStore} from 'aws-amplify/datastore';
import {Subscribers} from '../models';

const SubscriberForm = () => {
    const [submitted, setSubmitted] = useState(false)
    const [email, setEmail] = useState("")

    // function validateEmail() {
    //
    //     const email = document.getElementById("email");
    //
    //     if (email.validity.typeMismatch) {
    //         email.setCustomValidity("Enter a valid email");
    //     } else {
    //         email.setCustomValidity("");
    //     }
    // }

    async function handleSubmit(e) {
        e.preventDefault();
        try {
            await DataStore.save(
                new Subscribers({
                    "email": email,
                })
            );
            setSubmitted(true)
        } catch (error) {

        }
        setEmail("")
    }

    return (
        <div className="flex flex-col items-center justify-center gap-3">
            <p className="text-center text-white text-xl">
                Insights from Hemans Consult
            </p>
            {
                submitted &&
                <div className="text-white">
                    <p>Thank you for subscribing.</p>
                </div>
            }
            <form onSubmit={handleSubmit} className="">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                    <div className="w-full">
                        <input type="email" id="email"
                               name="email"
                               autoComplete={"off"}
                               value={email}
                               pattern="^(?!\.)(?!.*\.\.)[A-Za-z0-9!#$%&amp;'*+\/=?^_`.\{\|\}~\-]+(?<!\.)@([A-Za-z0-9\-]+\.)+[A-Za-z0-9\-]{2,20}$"
                               className="w-full border border-white text-gray-900 text-sm focus:ring-cyan-500 p-3"
                               placeholder="Enter your email"
                               onChange={(e) => {
                                   // validateEmail()
                                   setEmail(e.target.value)
                               }}
                               required
                        />
                    </div>
                    <button type="submit"
                            className={"text-white p-2.5 border border-white hover:text-cyan-700 hover:bg-white"}>
                        SUBSCRIBE
                    </button>
                </div>
            </form>
        </div>
    )
}

export default SubscriberForm;
