import React, { Fragment, useState } from "react";
import {
    ContactsCreateForm
} from '../ui-components';
import NavLinkButton from "../components/NavLinkButton";

function ContactPage() {
    const [submitted, setSubmitted] = useState(false)
    return (
        <>
            <div className="ContactPage">
                <div className="flex flex-col justify-center items-center py-10 sm:py-14 lg:py-22">
                    <div className="text-center max-w-5xl">
                        <h1 className="text-slate-900 font-extrabold text-4xl sm:text-5xl lg:text-6xl">
                            Let's get started!
                        </h1>
                        <p className="text-slate-600 text-xl p-2 text-center my-3">
                            Contact Request Form
                        </p>
                    </div>
                </div >
                <div className="container mx-auto md:max-w-lg pb-10">
                    {!submitted &&
                        <Fragment>
                            <ContactsCreateForm
                                onSuccess={() => {
                                    setSubmitted(true)
                                }}
                            />
                        </Fragment>
                    }
                    {submitted &&
                        <Fragment>
                            <div className="text-center">
                                <p className="text-cyan-700 font-bold text-xl p-2 text-center my-3">
                                    Thank you!. Form has been successfully submitted. <br />
                                    An Expert will contact you shortly.
                                </p>
                                <NavLinkButton to="/" text="Return to Homepage" />
                            </div>
                        </Fragment>
                    }
                </div>
            </div>
        </>
    )
}

export default ContactPage;