import MainNavBar from './components/MainNavBar';
import Footer from './components/Footer';
import { Outlet } from 'react-router-dom';
import './css/App.css';
import Consent from './components/Consent';
import { useCookies } from 'react-cookie';

function App() {
  const [cookies] = useCookies(['x_consent']);

  return (
    <div className="App relative ">
      <MainNavBar />
      {!cookies.x_consent && <Consent />}
      <div className="min-h-screen">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default App;
