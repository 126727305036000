import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';

import reportWebVitals from './reportWebVitals';
import {RouterProvider, createBrowserRouter} from 'react-router-dom';
import App from './App';
import Home from './pages/Home';
import Inprint from './pages/Inprint';
import Privacy from './pages/Privacy';
import ErrorPage from './components/errors/Error';
import ContactPage from './pages/ContactPage';
import Dashboard from './pages/Dashboard/Dashboard';

import {Amplify} from 'aws-amplify';
/*import awsconfig from './aws-exports';*/
import amplifyconfig from './amplifyconfiguration.json';
import "@aws-amplify/ui-react/styles.css";
import {CookiesProvider} from 'react-cookie';
if (process.env.NODE_ENV === 'production') {
    console.log = function () {}
}


Amplify.configure(amplifyconfig);

/*DataStore.configure({
    authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
});*/

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        errorElement: <ErrorPage/>,
        children: [
            {path: "/", element: <Home/>},
            {path: "/contact", element: <ContactPage/>},
            {path: "/inprint", element: <Inprint/>},
            {path: "/privacy", element: <Privacy/>}
        ]
    },
    {
        path: "/dashboard",
        element: <Dashboard/>,
        children: []
    }
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <CookiesProvider>
            <RouterProvider router={router}/>
        </CookiesProvider>
    </React.StrictMode>
);


reportWebVitals();
