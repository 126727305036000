function Privacy() {
    return (
        <>
            <div className="Privacy">
                <div className="text-center p-10">
                    <h1 className="font-bold text-2xl p-10">Data protection</h1>
                    {/* <p>according to §5 TMG (German Tele Media Act)</p> */}
                </div>
                <br />
                <div className="container mx-auto max-w-4xl">
                    <div className="flex flex-col justify-center">
                        <h1 className="font-bold">Data Owner</h1>
                        <p>Hemans Consult</p>
                        <p>info@hemansconsult.com</p>

                        <br />
                        <h1 className="font-bold">Website</h1>
                        <p>hemansconsult.com</p>
                        <br />
                        <h1 className="font-bold">Cloud Service Provider</h1>
                        <p>Amazon Web Services</p>
                        <br />
                        <h1 className="font-bold">Forms on our website</h1>
                        <p className="text-justify">
                            In order to be able contact you when you submit a form on this website, this website stores the information you have entered.
                            A form can be, for example, a general contact form or a form in which you request a quote for a consultant. We store the information you enter in the form,
                            such as name, email, telephone number and a description of your needs.
                            In addition, we store your IP address, the time of your transmission of your data and the URL from which you transmitted the information.
                        </p>
                        <br />
                        <h1 className="font-bold">Cookies</h1>
                        <p className="text-justify">
                            In order for this site to work optimally for you, we use small files called cookies.
                            A cookie is a small text file that a website saves on your computer or mobile device when you visit the website.
                            It allows the website to remember your actions and preferences to help provide you with relevant content online.
                            The use of cookies can usually be disabled by adjusting the browser settings, but this can have a negative impact on the user experience.
                            For more information on disabling cookies, visit www.aboutcookies.org.
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Privacy;