import { NavLink } from "react-router-dom";
import NavLinkButton from "./NavLinkButton";


function MainNavBar() {
    return (
        <nav className="p-2 container mx-auto">
            {/* <div className="container py-4"> */}
            <div className="flex justify-between items-center">
                <NavLink to="/" className="font-bold text-2xl">Hemans Consult</NavLink>
                <NavLinkButton className="ms-auto" to="/contact" text="Get started" />
            </div>
            {/* </div> */}
        </nav>
    )
}

export default MainNavBar;